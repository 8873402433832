import React, { memo } from 'react';
import cn from 'classnames';
import { PropsWithClassName } from '../../../../shared/types';

const Logo = ({ className }: PropsWithClassName) => {
  return (
    <div className={cn('relative flex-shrink-0 mr-auto xs:mr-0', className)}>
      <a href='/'>
        <span className='sr-only'>Thai Property Listings</span>
        <img className='h-10 w-auto' src='/icon.png' alt='Thai Property Listings' />
      </a>
    </div>
  );
};

export default memo(Logo);
