import React, { ChangeEvent } from 'react';
import cn from 'classnames';

import './style.css';
import { PropsWithClassName } from '../../../shared/types';

interface Props extends PropsWithClassName {
  reset: () => void;
  value: string | number;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  maxLength?: number;
}

const InputNumber = ({ reset, className, ...props }: Props) => {
  return (
    <div className='relative'>
      <input
        type='number'
        className={cn(
          'p-2 pr-8 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 border appearance-none',
          className
        )}
        {...props}
      />
      {reset && props.value && (
        <button
          className='absolute right-0 top-0 -translate-y-[2px] text-bold text-3xl px-2 h-full leading-none text-current'
          type='button'
          onClick={reset}
        >
          &#215;
        </button>
      )}
    </div>
  );
};

export default InputNumber;
