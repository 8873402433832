import React from 'react';
import { PropsWithClassName } from '../../shared/types';

const ArrowUp = ({ className }: PropsWithClassName) => {
  return (
    <svg fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' className={className}>
      <path
        d='m11.47 3.4697c0.2929-0.29289 0.7677-0.29289 1.0606 0l6 6c0.2929 0.29289 0.2929 0.76773 0 1.0606s-0.7677 0.2929-1.0606 0l-4.7197-4.7196v14.189c0 0.4142-0.3358 0.75-0.75 0.75s-0.75-0.3358-0.75-0.75v-14.189l-4.7197 4.7196c-0.29289 0.2929-0.76777 0.2929-1.0607 0s-0.29289-0.76774 0-1.0606l6-6z'
        clipRule='evenodd'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
};

export default ArrowUp;
