import React, { MouseEventHandler } from 'react';

interface AvatarProps {
  onClick?: MouseEventHandler;
}

const Avatar = ({ onClick }: AvatarProps) => {
  return (
    <button
      type='button'
      className='flex rounded-full bg-white text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100'
      id='user-menu-button'
      aria-expanded='false'
      aria-haspopup='true'
      onClick={onClick}
    >
      <span className='sr-only'>Open user menu</span>
      <img
        className='h-8 w-8 rounded-full'
        src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
        alt=''
      />
    </button>
  );
};

export default Avatar;
