import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  onClick?: () => void;
}

const SearchItem = ({ children, onClick }: Props) => {
  if (onClick) {
    return (
      <button
        className='text-white p-2 border-b last:border-0 border-white hover:bg-white hover:bg-opacity-20 w-full text-left overflow-ellipsis whitespace-nowrap overflow-hidden'
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
  return <div className='text-white p-2 border-t border-white w-full'>{children}</div>;
};

export default SearchItem;
