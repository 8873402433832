import React, { PropsWithChildren } from 'react';

import './style.css';
import { IFormGroup } from '../../Auth/Auth.components/FormGroup/FormGroup';
import { PropsWithClassName } from '../../../shared/types';

const Checkbox = ({ label, children, error, register, options, className, ...props }: PropsWithChildren & PropsWithClassName & IFormGroup & Record<string, unknown>) => {
  return (
    <div className={className}>
      <div className='flex items-center'>
        <div className='flex'>
          <input
            id={label}
            type='checkbox'
            className='shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800 appearance-none select-none w-4 h-4 border bg-no-repeat bg-contain bg-center checked:border-transparent checked:bg-current cursor-pointer'
            {...props}
            {...register?.(label, options)}
          />
        </div>
        <div className='ml-3'>
          <label htmlFor={label} className='text-sm dark:text-white select-none cursor-pointer'>
            {children}
          </label>
        </div>
      </div>
      {error && (
        <p className='text-xs text-red-600 mt-2' id='email-error'>
          {error.message}
        </p>
      )}
    </div>
  );
};

export default Checkbox;
