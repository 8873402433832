import React from 'react';
import cn from 'classnames';
import { PropsWithClassName } from '../../../../shared/types';

interface Props extends PropsWithClassName {
  active: boolean;
  toggleMenu: () => void;
}

const MenuButton = ({ active, toggleMenu, className }: Props) => {
  return (
    <div className={cn('relative flex-shrink-0 md:hidden mr-[2px]', className)}>
      <button
        type='button'
        className='inline-flex items-center justify-center rounded-md bg-transparent p-2 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
        aria-expanded='false'
        onClick={toggleMenu}
      >
        <span className='sr-only'>Open main menu</span>
        <svg
          className={cn('h-6 w-6', { hidden: active })}
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          aria-hidden='true'
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5' />
        </svg>
        <svg
          className={cn('h-6 w-6', { hidden: !active })}
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='currentColor'
          aria-hidden='true'
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
        </svg>
      </button>
    </div>
  );
};

export default MenuButton;
