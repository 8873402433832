import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import getListings from '../../queries/getListingsV2';

import Spinner from '../Spinner/Spinner';
import TableHeader from './Listing.components/TableHeader/TableHeader';
import Footer from './Listing.components/Footer/Footer';
import Header from './Listing.components/Header/Header';
import {COUNT_RECORDS_ON_PAGE} from "../../utils/constants";
import TableItem2 from "./Listing.components/TableItem/TableItem2";
import {useLocation} from "react-router-dom";


const LISTING = 'listing';

const Listing2 = () => {
  const [page, setPage] = useState(1);
  const { search } = useLocation();

  const { status, data } = useQuery(
      [LISTING, page, search],
      () => getListings({ page, search }),
      { refetchOnWindowFocus: false, keepPreviousData : true }
  );

  const handleNextPage = useCallback(() => {
    const newPage = page + 1;
    setPage(newPage);
  }, [page, setPage]);

  const handlePrevPage = useCallback(() => {
    const newPage = page - 1;
    setPage(newPage);
  }, [page, setPage]);

  const total = data?.estimatedTotalHits || 0
  const pageCount = getPageCount(total)
  //console.log(`Total ${total}; page count ${pageCount}`)

  return (
    <>
      <div className='bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-slate-900 dark:border-gray-700'>
        <Header />
        <div className={`${status !== 'loading' && 'overflow-x-auto'}`}>
          <table className='min-w-full divide-y divide-gray-200 dark:divide-gray-700'>
            <TableHeader />

            <tbody className='divide-y divide-gray-200 dark:divide-gray-700 relative'>
              {status === 'loading' ? (
                <tr className='h-64'>
                  <td className='absolute flex left-0 top-0 w-full h-full'>
                    <Spinner className='w-16 h-16 m-auto' />
                  </td>
                </tr>
              ) : (
                  data?.hits?.map((row) => <TableItem2 key={row.ID} item={row} openItem={() => 0} />)
              )}
            </tbody>
          </table>
        </div>
        <Footer
          total={total}
          currentPage={page}
          countPages={pageCount}
          nextPage={handleNextPage}
          prevPage={handlePrevPage}
        />
      </div>
    </>
  );
};

const getPageCount = (total) => Math.floor(total / COUNT_RECORDS_ON_PAGE) + (total % COUNT_RECORDS_ON_PAGE > 0 ? 1 : 0)

export default Listing2;
