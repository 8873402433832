import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FILTERS_PARAMS_NAMES } from '../../utils/constants';
import InputNumber from '../../components/UI/InputNumber/InputNumber';

const initialValues = {
  min: '',
  max: '',
} as {
  min: '' | number;
  max: '' | number;
};

export const FloorFilter = ({ closePopup }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    const newValues = {
      ...initialValues,
    };
    if (searchParams.get(FILTERS_PARAMS_NAMES.floor.from)) {
      newValues.min = Number(searchParams.get(FILTERS_PARAMS_NAMES.floor.from));
    }
    if (searchParams.get(FILTERS_PARAMS_NAMES.floor.to)) {
      newValues.max = Number(searchParams.get(FILTERS_PARAMS_NAMES.floor.to));
    }

    setValues(newValues);
  }, []);

  const changeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const targetValue = Number(target.value);
    const newValue = targetValue === 0 ? '' : targetValue;

    setValues({
      ...values,
      [target.name]: newValue,
    });
  };

  const handleApplyFilter = (event) => {
    event.preventDefault();
    if (values.min) {
      setSearchParams((current) => {
        current.set(FILTERS_PARAMS_NAMES.floor.from, String(values.min));
        return current;
      });
    } else {
      setSearchParams((current) => {
        current.delete(FILTERS_PARAMS_NAMES.floor.from);
        return current;
      });
    }
    if (values.max) {
      setSearchParams((current) => {
        current.set(FILTERS_PARAMS_NAMES.floor.to, String(values.max));
        return current;
      });
    } else {
      setSearchParams((current) => {
        current.delete(FILTERS_PARAMS_NAMES.floor.to);
        return current;
      });
    }
    closePopup();
  };

  const handleReset = (name) => {
    setValues({
      ...values,
      [name]: '',
    });
  };

  return (
    <form onSubmit={handleApplyFilter} className='space-y-2 dark:text-white'>
      <div className='flex items-center space-x-2'>
        <InputNumber
          className='w-20'
          name='min'
          value={values.min}
          onChange={changeInput}
          reset={() => handleReset('min')}
          placeholder='min'
        />
        <InputNumber
          className='w-20'
          name='max'
          value={values.max}
          onChange={changeInput}
          reset={() => handleReset('max')}
          placeholder='max'
        />
      </div>
      <button className='ml-auto block px-2 py-0.5' type='submit'>
        Apply
      </button>
    </form>
  );
};
