import React from 'react';
import cn from 'classnames';
import AltMenu from '../AltMenu/AltMenu';
import Container from '../../../Container/Container';
import MainMenu from '../MainMenu/MainMenu';
import Notification from '../../../Notification/Notification';
import Avatar from '../../../Profile/Profile.components/Avatar/Avatar';
import Menu from '../../../Profile/Profile.components/Menu/Menu';

interface Props {
  active: boolean;
}

const MobileMenu = ({ active }: Props) => {
  return (
    <div
      className={cn(
        'absolute inset-x-0 top-0 z-10 mx-auto w-full max-w-3xl origin-top transform pb-4 pt-40 xs:pt-28 transition bg-indigo-600 -translate-y-full',
        active && 'translate-y-0'
      )}
    >
      <Container>
        {/* <AltMenu className='flex justify-center flex-col xs:flex-row items-center' /> */}
        <AltMenu className='justify-center flex-col xs:flex-row items-center hidden' />
        <MainMenu className='flex justify-center flex-col xs:flex-row py-4 border-t border-b border-white border-opacity-20 my-4' />
        {/* <div className='flex items-center justify-between px-3'> */}
        <div className='hidden items-center justify-between px-3'>
          <Avatar />
          <Notification />
        </div>
        <Menu isActive={true} className='pt-4 pb-2 text-indigo-100' />
      </Container>
    </div>
  );
};

export default MobileMenu;
