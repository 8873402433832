import React, { ChangeEvent, useEffect, useState } from 'react';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import { FILTERS_PARAMS_NAMES } from '../../utils/constants';
import { useSearchParams } from 'react-router-dom';


const OwnershipFilter = ({ closePopup }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [checked, setCheched] = useState(false);

  useEffect(() => {
    if (searchParams.has(FILTERS_PARAMS_NAMES.ownership)) {
      setCheched(true);
    } else {
      setCheched(false)
    }
  }, [searchParams]);

  const changeUnput = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.checked;
    if (value) {
      setSearchParams((current) => {
        current.set(FILTERS_PARAMS_NAMES.ownership, "");
        return current;
      })
    } else {
      setSearchParams((current) => {
        current.delete(FILTERS_PARAMS_NAMES.ownership);
        return current;
      })
    }
    closePopup();
  }

  return (
    <Checkbox className='whitespace-nowrap' type="checkbox" label="foreign quota" checked={checked} onChange={changeUnput} >Foreign Quota</Checkbox>
  )
}

export default OwnershipFilter