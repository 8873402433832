import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import Profile from './components/Profile/Profile';
import Home from '../pages/Home';
// import Project from '../pages/Project';
import NotFound from '../pages/NotFound';
import AuthPage from '../pages/AuthPage/AuthPage';
import PrivateRoute from './PrivateRoute';
import { AuthTypes } from '../shared/types';
import EmailConfirmation from '../pages/EmailConfirmation';
// import AuthPage from './pages/AuthPage/AuthPage';
// import PrivateRoute from './components/PrivateRoute/PrivateRoute';
// import Leads from './components/Leads/Leads';
// import AgentLeads from './components/AgentLeads/AgentLeads';
// import LeadsWrapper from './components/Leads/LeadsWrapper';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<PrivateRoute />}>
        <Route path='/' element={<Home />} />
        {/* <Route path='/project/:id' element={<Project />} /> */}
      </Route>
      {/* </Route> */}
      {/* <Route exact path='/agent-leads' element={<PrivateRoute />}>
        <Route exact path='/agent-leads' element={<AgentLeads />} />
        <Route exact path='/agent-leads/:id' element={<AgentLeads />} />
      </Route>*/}
      <Route path='/404' element={<NotFound />} />
      <Route path='/auth' element={<Navigate to={`/auth/${AuthTypes['sign-in']}`} />} />
      <Route path='/auth/:authType' element={<AuthPage />} />
      <Route path='/auth/email-confirmation' element={<EmailConfirmation />} />
      <Route path='*' element={<Navigate to={`/auth/${AuthTypes['sign-in']}`} />} />
    </Routes>
  );
};

export default AppRoutes;
