import React, { memo } from 'react';
import { AuthTypes } from '../../../../shared/types';

const content = {
  [AuthTypes['sign-in']]: 'Sign in',
  [AuthTypes['sign-up']]: 'Sign up',
  [AuthTypes['forgot-password']]: 'Reset password',
  [AuthTypes['reset-password']]: 'Confirm',
};

const Button = ({ authType }: { authType: AuthTypes }) => {
  return (
    <button
      type='submit'
      className='py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800'
    >
      {content[authType]}
    </button>
  );
};

export default memo(Button);
