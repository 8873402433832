import React from 'react';
import { PropsWithClassName } from '../../../../shared/types';

interface MenuItemProps extends PropsWithClassName {
  // isMenuActive: boolean;
  href?: string;
  id: number | string;
  text: string;
  onClick?: () => void;
}

interface ComponentProps extends PropsWithClassName {
  role?: string;
  // tabIndex?: string;
  onClick?: () => void;
  href?: string;
  id: string;
  text: string;
}

const Component = (props: ComponentProps) => {
  const { onClick, href, text, ...rest } = props;
  if (onClick) {
    return (
      <button onClick={onClick} {...rest}>
        {text}
      </button>
    );
  }
  return (
    <a href={href} {...rest}>
      {text}
    </a>
  );
};

const MenuItem = ({ id, href, text, onClick }: MenuItemProps) => {
  return (
    <Component
      href={href}
      className='block px-4 py-2 text-sm text-white md:text-gray-700 hover:bg-indigo-500 rounded-md md:rounded-none hover:bg-opacity-75 md:hover:bg-gray-100 md:hover:bg-opacity-100 focus:outline-none focus:bg-gray-200 w-full text-start'
      role='menuitem'
      id={`user-menu-item-${id}`}
      // tabIndex={isMenuActive ? '0' : '-1'}
      onClick={onClick}
      text={text}
    />
  );
};

export default MenuItem;
