import React, { memo, useMemo } from 'react';
import { COUNT_RECORDS_ON_PAGE } from '../../../../utils/constants';

interface Props {
  total: number;
  countPages: number;
  currentPage: number;
  nextPage: () => void;
  prevPage: () => void;
}

const Footer = ({ total, countPages, currentPage, nextPage, prevPage }: Props) => {
  const range = useMemo(() => {
    if (currentPage === countPages) {
      return `${(currentPage - 1) * COUNT_RECORDS_ON_PAGE + 1} – ${total}`;
    } else {
      return `${(currentPage - 1) * COUNT_RECORDS_ON_PAGE + 1} - ${currentPage * COUNT_RECORDS_ON_PAGE}`;
    }
  }, [countPages, currentPage, total]);
  return (
    <div className='px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-gray-700'>
      <div>
        <p className='text-sm text-gray-600 dark:text-gray-400'>
          <span className='font-semibold text-gray-800 dark:text-gray-200'>{range} </span>
          of
          <span className='font-semibold text-gray-800 dark:text-gray-200'> {total}</span> results
        </p>
      </div>

      <div>
        <div className='inline-flex gap-x-2'>
          {currentPage > 1 && (
            <button
              type='button'
              className='py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800'
              onClick={prevPage}
            >
              <svg
                className='w-3 h-3'
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                viewBox='0 0 16 16'
              >
                <path
                  fillRule='evenodd'
                  d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'
                />
              </svg>
              Prev
            </button>
          )}

          {currentPage !== countPages && (
            <button
              type='button'
              className='py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800'
              onClick={nextPage}
            >
              Next
              <svg
                className='w-3 h-3'
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                viewBox='0 0 16 16'
              >
                <path
                  fillRule='evenodd'
                  d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);
