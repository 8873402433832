import React from 'react';
import cn from 'classnames';

import MenuItem from './MenuItem';
import auth from '../../../../utils/auth';
import { useNavigate } from 'react-router-dom';

interface MenuProps {
  isActive: boolean;
  className?: string;
}

// const menuItems = [
//   {
//     id: 1,
//     href: '#',
//     text: 'Your Profile',
//   },
//   {
//     id: 2,
//     href: '#',
//     text: 'Settings',
//   },
// ];

const Menu = ({ isActive, className }: MenuProps) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.clearToken()
    navigate('/auth/login', { replace: true });
  }
  return (
    <div
      className={cn('origin-top-right py-1 focus:outline-none pointer-events-none', [
        isActive
          ? 'transform opacity-100 scale-100 pointer-events-auto'
          : 'transform transition ease-in duration-75 opacity-0 sm:scale-95',
        className,
      ])}
      role='menu'
      aria-orientation='vertical'
      aria-labelledby='user-menu-button'
      aria-hidden={!isActive}
    >
      {/* {menuItems.map((item) => (
        <MenuItem key={item.id} {...item} />
      ))} */}
      <MenuItem id={3} onClick={handleLogout} text="Sign out" />
    </div>
  );
};

export default Menu;
