import { createSlice } from '@reduxjs/toolkit';
// import { fetchUserApiData, fetchUsersApiData } from '../../api/users';

const initialState = {
  user: null,
  users: [],
};

// export const fetchUserData = createAsyncThunk('user/fetchUser', async () => {
//   const response = await fetchUserApiData();
//   return response;
// });

// export const fetchUsersData = createAsyncThunk('user/fetchUsers', async () => {
//   const response = await fetchUsersApiData();
//   return response.map((item) => ({
//     ...item,
//     value: item.username,
//     label: item.username,
//   }));
// });

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeUser: (state) => {
      state.user = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchUserData.fulfilled, (state, action) => {
  //       state.user = action.payload;
  //     })
  //     .addCase(fetchUsersData.fulfilled, (state, action) => {
  //       state.users = action.payload;
  //     });
  // },
});

export const { removeUser, setUser } = userSlice.actions;

export const getUser = (state) => state.user.user;
export const getUsers = (state) => state.user.users;
export const isAdmin = (state) => state.user.user?.role?.type === 'admin';

export default userSlice.reducer;
