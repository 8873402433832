import React from 'react'
import Container from '../components/Container/Container'
import Listing from '../components/Listing/Listing2'
import MainLayout from '../components/MainLayout/MainLayout'

const Home = () => {
  return (
    <MainLayout>
      <Container>
        <div className='flex flex-col'>
          <div className='-m-1.5'>
            <div className='p-1.5 min-w-full align-middle'>
              <div className='bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-slate-900 dark:border-gray-700'>
                <Listing />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </MainLayout>
  )
}

export default Home