import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import { PropsWithClassName } from '../../../../shared/types';

const TableHeaderItem = ({ className, children, ...props }: PropsWithClassName & PropsWithChildren) => {
  return (
    <th scope='col' className={cn('px-5 py-3', className)} {...props}>
      <div className='flex items-center gap-x-2'>
        <span className='text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-gray-200 flex'>
          {children}
        </span>
      </div>
    </th>
  );
};

export default TableHeaderItem;
