import React, { useEffect } from 'react';
import AppRoutes from '../router/Router';
import { useQuery } from '@tanstack/react-query';
import auth from '../utils/auth';
import { getUserData } from '../queries/getUserData';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getMessage, isVisible } from '../redux/notification/notificationSlice';
import cn from 'classnames';

function App() {
  /*const authToken = auth.getToken();
  const { status } = useQuery(['UserData'], getUserData, {
    refetchOnWindowFocus: false,
    enabled: true, //Boolean(authToken),
    retry: false,
  });
  const navigate = useNavigate();


  useEffect(() => {
    if (!authToken) {
      return;
    }

    if (status === 'error') {
      auth.clearToken();
      navigate('/auth/login', { replace: true });
    }
  }, [authToken, status, navigate]);*/

    const isNotificationVisible = useSelector(isVisible);
    const notificationMessage = useSelector(getMessage);

  return (
    <div className='App relative'>
      <div
        className={cn(
          'absolute px-3 py-2 z-40 text-red-600 sm:text-lg text-center left-1/2 -translate-x-1/2 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700 top-4 transition duration-2',
          isNotificationVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-full'
        )}
      >
        {notificationMessage}
      </div>
      <AppRoutes />
    </div>
  );
}

export default App;
