import { API, AUTH_TOKEN, FILTERS_PARAMS_NAMES, SORT_PARAMS_NAMES } from './constants';

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const getAuthURL = (authType) => {
  let requestURL;

  switch (authType) {
    case 'sign-in':
      requestURL = `${API}/auth/local`;
      break;
    case 'sign-up':
      requestURL = `${API}/auth/local/register`;
      break;
    case 'reset-password':
      requestURL = `${API}/auth/reset-password`;
      break;
    case 'forgot-password':
      requestURL = `${API}/auth/forgot-password`;
      break;
    default:
  }

  return requestURL;
};

export const getFormTitle = (authType) => {
  let title;

  switch (authType) {
    case 'login':
      title = `Sign In`;
      break;
    case 'register':
      title = `Sign Up`;
      break;
    case 'reset-password':
      title = `Reset Password`;
      break;
    case 'forgot-password':
      title = `Forgot Password`;
      break;
    default:
  }

  return title;
};

export const getFormatedDateAndMonth = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  return `${day}/${month}/${year}`;
};

export const copyToClipboard = (textToCopy) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
};

export const addQueryParams = ({ url, searchParams }: { url: URL; searchParams: URLSearchParams }): URL => {
  let sortCounter = 0;
  for (const [key, value] of searchParams) {
    switch (key) {
      case FILTERS_PARAMS_NAMES.project_name:
        url.searchParams.append('filters[project_name][$containsi]', value);
        break;
      case FILTERS_PARAMS_NAMES.size.from:
        url.searchParams.append('filters[size][$gte]', value);
        break;
      case FILTERS_PARAMS_NAMES.size.to:
        url.searchParams.append('filters[size][$lte]', value);
        break;
      case FILTERS_PARAMS_NAMES.price.from:
        url.searchParams.append('filters[price][$gte]', value);
        break;
      case FILTERS_PARAMS_NAMES.price.to:
        url.searchParams.append('filters[price][$lte]', value);
        break;
      case FILTERS_PARAMS_NAMES.ownership:
        url.searchParams.append('filters[ownership][$containsi]', 'f');
        break;
      case FILTERS_PARAMS_NAMES.floor.from:
        url.searchParams.append('filters[floor][$gte]', value);
        break;
      case FILTERS_PARAMS_NAMES.floor.to:
        url.searchParams.append('filters[floor][$lte]', value);
        break;
      case SORT_PARAMS_NAMES.company:
        url.searchParams.append(`sort[${sortCounter}]`, `source:${value}`);
        sortCounter++;
        break;
      case SORT_PARAMS_NAMES.view:
        url.searchParams.append(`sort[${sortCounter}]`, `view:${value}`);
        sortCounter++;
        break;
      case SORT_PARAMS_NAMES.price:
        url.searchParams.append(`sort[${sortCounter}]`, `price:${value}`);
        sortCounter++;
        break;
      case SORT_PARAMS_NAMES.project:
        url.searchParams.append(`sort[${sortCounter}]`, `project_name:${value}`);
        sortCounter++;
        break;
      case SORT_PARAMS_NAMES.floor:
        url.searchParams.append(`sort[${sortCounter}]`, `floor:${value}`);
        sortCounter++;
        break;
      case SORT_PARAMS_NAMES.size:
        url.searchParams.append(`sort[${sortCounter}]`, `size:${value}`);
        sortCounter++;
        break;
      default:
        break;
    }
  }

  if (!sortCounter) {
    url.searchParams.append('sort[0]', 'price');
  }
  return url;
};
