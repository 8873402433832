import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isVisible: false,
  type: 'error',
  message: 'Your account email is not confirmed',
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    showMessage: (state) => {
      state.isVisible = true;
    },
    hideMessage: (state) => {
      state.isVisible = false;
    },
  },
});

export const { setMessage, showMessage, hideMessage } = notificationSlice.actions;

export const getMessage = (state) => state.notification.message;
export const isVisible = (state) => state.notification.isVisible;

export default notificationSlice.reducer;
