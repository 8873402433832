import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { hideMessage, showMessage } from '../redux/notification/notificationSlice';

export const useNotification = () => {
  const dispatch = useDispatch();

  let timerId = null;

  const showMessageTimeout = useCallback(
    (timeout) => {
      dispatch(showMessage());
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        dispatch(hideMessage());
      }, timeout);
    },
    [dispatch]
  );

  return { showMessageTimeout };
};
