import React from 'react';
import cn from 'classnames';

const Component = ({ children, ...props }) => {
  const { onClick, href, ...rest } = props;
  if (onClick) {
    return (
      <button onClick={onClick} {...rest}>
        {children}
      </button>
    );
  }
  return (
    <a href={href} {...rest}>
      {children}
    </a>
  );
};

const MainMenuItem = ({ children, className, ...props }) => {
  return (
    <Component
      className={cn(
        'text-indigo-100 text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10',
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

export default MainMenuItem;
