import React, { memo } from 'react';
import cn from 'classnames';
import { PropsWithClassName } from '../../../../shared/types';

const AltMenu = ({ className }: PropsWithClassName) => {
  return (
    <div className={cn('lg:order-none items-center space-y-3 xs:space-y-0 xs:space-x-4 m-auto', className)}>
      <a href='#' className='bg-indigo-700 text-white px-3 py-2 rounded-md text-sm font-medium' aria-current='page'>
        Property Listings
      </a>

      <a
        href='#'
        className='text-white hover:bg-indigo-500 hover:bg-opacity-75 px-3 py-2 rounded-md text-sm font-medium'
      >
        Company Dashboard
      </a>
    </div>
  );
};

export default memo(AltMenu);
