import React, { memo } from 'react';
import TableHeaderItem from './TableHeaderItem';
import withSortFunction from '../../../../features/hocs/withSortFunction';
import { SORT_PARAMS_NAMES } from '../../../../utils/constants';

const TableHeader = () => {
  const CompanySortTableHeaderItem = withSortFunction(TableHeaderItem);
  const ViewSortTableHeaderItem = withSortFunction(TableHeaderItem);
  const PriceSortTableHeaderItem = withSortFunction(TableHeaderItem);
  const ProjectSortTableHeaderItem = withSortFunction(TableHeaderItem);
  const FlorSortTableHeaderItem = withSortFunction(TableHeaderItem);
  const SizeSortTableHeaderItem = withSortFunction(TableHeaderItem);

  return (
    <thead className='bg-gray-50 dark:bg-slate-800'>
      <tr>
        {/* <th scope='col' className='pl-6 py-3 text-left'>
          <label htmlFor='hs-at-with-checkboxes-main' className='flex'>
            <input
              type='checkbox'
              className='shrink-0 border-gray-200 rounded text-blue-600 pointer-events-none focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800'
              id='hs-at-with-checkboxes-main'
            />
            <span className='sr-only'>Checkbox</span>
          </label>
        </th> */}

        <ProjectSortTableHeaderItem sortedField={SORT_PARAMS_NAMES.project} className='text-left'>
          Project
        </ProjectSortTableHeaderItem>

        <TableHeaderItem className='text-left'>Unit</TableHeaderItem>

        <FlorSortTableHeaderItem sortedField={SORT_PARAMS_NAMES.floor} className='text-left'>
          Floor
        </FlorSortTableHeaderItem>

        <SizeSortTableHeaderItem sortedField={SORT_PARAMS_NAMES.size} className='text-left'>
          Size(SQM)
        </SizeSortTableHeaderItem>

        <PriceSortTableHeaderItem sortedField={SORT_PARAMS_NAMES.price} className='text-right'>
          Price
        </PriceSortTableHeaderItem>

        <TableHeaderItem className='text-left'>Ownership</TableHeaderItem>

        <ViewSortTableHeaderItem className='text-left' sortedField={SORT_PARAMS_NAMES.view}>
          View
        </ViewSortTableHeaderItem>

        <CompanySortTableHeaderItem
          sortedField={SORT_PARAMS_NAMES.company}
          className='pl-5 lg:pl-3 xl:pl-0 pr-6 text-left'
        >
          ID
        </CompanySortTableHeaderItem>

        <th scope='col' className='px-6 py-3 text-right'></th>
      </tr>
    </thead>
  );
};

export default memo(TableHeader);
