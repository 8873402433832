import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthTypes } from '../../shared/types';
import { useForm } from 'react-hook-form';
import Title from '../../components/Auth/Auth.components/Title/Title';
import Subtitle from '../../components/Auth/Auth.components/Subtitle/Subtitle';
import FormGroup from '../../components/Auth/Auth.components/FormGroup/FormGroup';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import Button from '../../components/Auth/Auth.components/Button/Button';
import { ERROR_MESSAGES } from '../../utils/constants';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import AuthContainer from '../../components/Auth/AuthContainer';
import { getAuthURL } from '../../utils/helpers';
import { authRequest } from '../../queries/authRequest';
import auth from '../../utils/auth';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/user/userSlice';
import { views } from './views';
import { keys } from './form-fields';
import { setMessage } from '../../redux/notification/notificationSlice';
import { useNotification } from '../../hooks/useNotification';

export interface IAuthLabels {
  email: string;
  password: string;
  rememberMe: string;
  confirmPassword: string;
}

const getCheckboxContent = (label: string) => {
  if (label === 'rememberMe') {
    return 'Remember me';
  }
  return (
    <>
      I accept the{' '}
      <Link className='text-blue-600 decoration-2 hover:underline font-medium' to='#'>
        Terms and Conditions
      </Link>
    </>
  );
};

const GetFormGroup = (props) => {
  if (props.type === 'checkbox') {
    return <Checkbox {...props}>{getCheckboxContent(props.label)}</Checkbox>;
  }
  return <FormGroup {...props} />;
};

const AuthPage = () => {
  const { authType } = useParams() as { authType: AuthTypes };
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IAuthLabels>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showMessageTimeout } = useNotification();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const redirectUser = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const correctPath = Object.keys(AuthTypes).some((type) => type === authType);

  useEffect(() => {
    if (!correctPath) {
      navigate(`/auth/${AuthTypes['sign-in']}`);
    }
    if (auth.getToken()) {
      redirectUser();
    }
  }, [redirectUser, authType]);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const requestUrl = getAuthURL(authType);

    // TODO: add error handling
    try {
      const formValues = Object.keys(keys[authType]).reduce((res, key) => {
        return Object.assign(res, { [key]: values[key] });
      }, {}) as Record<string, string>;

      if (authType === AuthTypes['reset-password']) {
        const code = searchParams.get('code');
        formValues.code = code;
      }

      if (authType === AuthTypes['sign-in']) {
        formValues.identifier = formValues.email;
      }

      const response = await authRequest({ requestUrl, body: formValues });

      if (authType === AuthTypes['sign-in']) {
        auth.setToken(response.jwt, values.rememberMe);
        dispatch(setUser(response.user));

        redirectUser();
        return;
      }

      if (authType === AuthTypes['sign-up']) {
        navigate('/auth/sign-in');
        dispatch(setMessage(`Please check ${formValues.email} to confirm email!`));
        showMessageTimeout(5000);
      }

      if (authType === AuthTypes['forgot-password']) {
        dispatch(setMessage(`Please check ${formValues.email} to reset your password!`));
        showMessageTimeout(5000);
        setValue('email', '');
      }

      if (authType === AuthTypes['reset-password']) {
        dispatch(setMessage(`Password was changed.`));
        showMessageTimeout(3000);
        auth.setToken(response.jwt, values.rememberMe);
        dispatch(setUser(response.user));

        redirectUser();
      }
    } catch (error) {
      console.error(error);
      dispatch(setMessage(error.message));
      showMessageTimeout(5000);
    } finally {
      setIsLoading(false);
    }
  };

  const fields = views[authType];

  if (!correctPath) {
    return;
  }

  return (
    <AuthContainer isLoading={isLoading}>
      <div className='text-center mb-5'>
        <Title authType={authType} />
        <Subtitle authType={authType} />
      </div>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <div className='grid gap-y-4'>
          {/* TODO add types */}
          {fields?.map((item) => {
            let options = { ...item.options };
            if (item.label === 'confirmPassword' || item.label === 'passwordConfirmation') {
              options = Object.assign(options, {
                validate: (value) => value === getValues('password') || ERROR_MESSAGES.confirm,
              });
            }

            return (
              <GetFormGroup
                key={item.label || item.title}
                {...item}
                register={register}
                error={errors?.[item.label]}
                options={options}
              />
            );
          })}
          <Button authType={authType} />
        </div>
      </form>
    </AuthContainer>
  );
};

export default AuthPage;
