import React from 'react';
import { Link } from 'react-router-dom';
import { AuthTypes } from '../../../../shared/types';
import Input from '../Input/Input';
import { FieldError, UseFormRegister } from 'react-hook-form';

export interface IFormGroup {
  label: string;
  type: string;
  register?: UseFormRegister<Record<string, string>>;
  error?: FieldError;
  options?: Record<string, unknown>;
  extraLink?: boolean;
  title?: string;
}

const FormGroup = ({
  title,
  label,
  type,
  extraLink,
  register,
  error,
  options,
}: IFormGroup) => {
  return (
    <div>
      <div className='flex items-center justify-between mb-2'>
        <label htmlFor={label} className='block text-sm dark:text-white'>
          {title}
        </label>
        {extraLink && (
          <Link
            className='text-sm text-blue-600 decoration-2 hover:underline font-medium'
            to={`/auth/${AuthTypes['forgot-password']}`}
          >
            Forgot password?
          </Link>
        )}
      </div>
      <Input label={label} type={type} register={register} error={error} options={options} />
    </div>
  );
};

export default FormGroup;
