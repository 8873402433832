import { AuthTypes } from '../../shared/types';

export const keys = {
  [AuthTypes['sign-up']]: {
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    rememberMe: true,
  },
  [AuthTypes['sign-in']]: {
    email: '',
    password: '',
    rememberMe: true,
  },
  [AuthTypes['forgot-password']]: {
    email: '',
  },
  [AuthTypes['reset-password']]: {
    password: '',
    passwordConfirmation: '',
    code: '',
  },
};
