import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { useClickAway } from 'react-use';

const withPopup = (Component, Content) => (props) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setVisible(false);
  });

  const handleClick = () => {
    setVisible(!visible);
    props.onClick?.();
  };

  return (
    <div className='relative' ref={ref}>
      <Component {...props} onClick={handleClick} />
      <div
        className={cn(
          'absolute z-10 left-1/2 top-full -translate-x-1/2 translate-y-2 bg-white border-2 border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700 transition p-2',
          visible ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'
        )}
      >
        <Content closePopup={() => setVisible(false)} />
      </div>
    </div>
  );
};

export default withPopup;
