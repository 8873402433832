export interface PropsWithClassName {
  className?: string;
}

// eslint-disable-next-line
export enum AuthTypes {
  'sign-in' = 'sign-in',
  'sign-up' = 'sign-up',
  'reset-password' = 'reset-password',
  'forgot-password' = 'forgot-password',
}

export const enum AuthLabels {
  email = 'email',
  password = 'password',
  rememberMe = 'rememberMe',
  confirmPassword = 'confirmPassword',
}

// export const auth = {
//   'sign-in': 'sign-in',
//   'sign-up': 'sign-up',
//   'reset-password': 'reset-password',
//   'forgot-password': 'forgot-password',
// };

// export type AuthTypes = keyof typeof auths;

// const newObj: {[key in AuthTypes]: key} = {
//   value1: 'value1',
//   value2: 'value2',
//   value3: 'value3',
// };
