import 'whatwg-fetch';
import {
  COUNT_RECORDS_ON_PAGE,
  FILTERS_PARAMS_NAMES,
  MEILISEARCH_API,
  MEILISEARCH_INDEX,
  MEILISEARCH_KEY, SORT_PARAMS_NAMES
} from '../utils/constants';

const getListingsV2 = async ({ page, search }: { page: number, search: string }) => {
  const { filterString, sortArray } = parseQueryString(search);

  return fetch(`${MEILISEARCH_API}/multi-search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${MEILISEARCH_KEY}`,
    },
    body: JSON.stringify({
      "queries":[
          {
            "indexUid": MEILISEARCH_INDEX,
            "filter": filterString,
            "sort": sortArray,
            "limit": COUNT_RECORDS_ON_PAGE,
            "offset": (page - 1) * COUNT_RECORDS_ON_PAGE
          }
      ]
    })
  }).then(res => res.json())
      .then(res => res?.results[0]);
};

const filterMapping : { [key: string]: string } = {
  [FILTERS_PARAMS_NAMES.size.from]: "REAL_HOMES_property_size >= ",
  [FILTERS_PARAMS_NAMES.size.to]: "REAL_HOMES_property_size <= ",
  [FILTERS_PARAMS_NAMES.price.from]: "REAL_HOMES_property_price >= ",
  [FILTERS_PARAMS_NAMES.price.to]: "REAL_HOMES_property_price <= ",
  [FILTERS_PARAMS_NAMES.floor.from]: "REAL_HOMES_property_floorf >= ",
  [FILTERS_PARAMS_NAMES.floor.to]: "REAL_HOMES_property_floorf <= ",
  [FILTERS_PARAMS_NAMES.ownership]: "REAL_HOMES_property_garage = Foreign",
  [FILTERS_PARAMS_NAMES.project_name]: "ParentSlug = "
}

const mapFilters = (pair: [string, string]) => filterMapping[pair[0]]?.concat(pair[1])

const sortMapping : { [key: string]: string } = {
  [SORT_PARAMS_NAMES.size + 'asc']: "REAL_HOMES_property_size:asc",
  [SORT_PARAMS_NAMES.size + 'desc']: "REAL_HOMES_property_size:desc",
  [SORT_PARAMS_NAMES.price + 'asc']: "REAL_HOMES_property_price:asc",
  [SORT_PARAMS_NAMES.price + 'desc']: "REAL_HOMES_property_price:desc",
  [SORT_PARAMS_NAMES.floor + 'asc']: "REAL_HOMES_property_floorf:asc",
  [SORT_PARAMS_NAMES.floor + 'desc']: "REAL_HOMES_property_floorf:desc",
  [SORT_PARAMS_NAMES.project + 'asc']: "Title:asc",
  [SORT_PARAMS_NAMES.project + 'desc']: "Title:desc",
  [SORT_PARAMS_NAMES.view + 'asc']: "REAL_HOMES_property_bathrooms:asc",
  [SORT_PARAMS_NAMES.view + 'desc']: "REAL_HOMES_property_bathrooms:desc",
}

const mapSorting = (pair: [string, string]) => sortMapping[pair[0]+pair[1]]

const parseQueryString = (searchString: string) => {
  const searchParam = new URLSearchParams(searchString)

  const searchArray = Array.from(searchParam, mapFilters).filter((value) => value != null);
  searchArray.unshift("NOT REAL_HOMES_property_id IS EMPTY AND ParentSlug != '0'")
  const filterString = searchArray.join(" AND ")

  const sortArray = Array.from(searchParam, mapSorting).filter((value) => value != null);

  const result = { filterString, sortArray };
  //console.log("filter and sort", result);
  return result;
}

export default getListingsV2;
