import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { AuthTypes } from '../../../../shared/types';

const texts = {
  [AuthTypes['sign-in']]: {
    title: "Don't have an account yet?",
    link: AuthTypes['sign-up'],
    linkText: 'Sign up here',
  },
  [AuthTypes['sign-up']]: {
    title: 'Already have an account?',
    link: AuthTypes['sign-in'],
    linkText: 'Sign in here',
  },
  [AuthTypes['forgot-password']]: {
    title: 'Remember your password?',
    link: AuthTypes['sign-in'],
    linkText: 'Sign in here',
  },
  [AuthTypes['reset-password']]: {
    title: 'Enter your new password',
    link: '',
    linkText: '',
  },
};

const Subtitle = ({ authType }: { authType: AuthTypes }) => {
  const content = texts[authType];

  return (
    <p className='mt-2 text-sm text-gray-600 dark:text-gray-400'>
      {content.title}{' '}
      {content.link && (
        <Link className='text-blue-600 decoration-2 hover:underline font-medium' to={`/auth/${content.link}`}>
          {content.linkText}
        </Link>
      )}
    </p>
  );
};

export default memo(Subtitle);
