import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FILTERS_PARAMS_NAMES } from '../../utils/constants';
import InputText from '../../components/UI/InputText/InputText';

const initialValues = {
  min: '',
  max: '',
} as {
  min: string;
  max: string;
};

export const PriceFilter = ({ closePopup }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    const newValues = {
      ...initialValues,
    };
    if (searchParams.get(FILTERS_PARAMS_NAMES.price.from)) {
      newValues.min = searchParams.get(FILTERS_PARAMS_NAMES.price.from).replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
    }
    if (searchParams.get(FILTERS_PARAMS_NAMES.price.to)) {
      newValues.max = searchParams.get(FILTERS_PARAMS_NAMES.price.to).replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
    }

    setValues(newValues);
  }, [searchParams]);

  const changeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const targetValue = Number(target.value.replace(/\D/g, ''));
    const newValue = targetValue === 0 ? '' : String(targetValue).replace(/(\d)(?=(\d{3})+$)/g, '$1 ');

    setValues({
      ...values,
      [target.name]: newValue,
    });
  };

  const handleApplyFilter = (event) => {
    event.preventDefault();
    if (values.min) {
      setSearchParams((current) => {
        current.set(FILTERS_PARAMS_NAMES.price.from, String(values.min).replace(/\s/g, ''));
        return current;
      });
    } else {
      setSearchParams((current) => {
        current.delete(FILTERS_PARAMS_NAMES.price.from);
        return current;
      });
    }
    if (values.max) {
      setSearchParams((current) => {
        current.set(FILTERS_PARAMS_NAMES.price.to, String(values.max).replace(/\s/g, ''));
        return current;
      });
    } else {
      setSearchParams((current) => {
        current.delete(FILTERS_PARAMS_NAMES.price.to);
        return current;
      });
    }
    closePopup();
  };

  const handleReset = (name) => {
    setValues({
      ...values,
      [name]: '',
    });
  };

  return (
    <form onSubmit={handleApplyFilter} className='space-y-2 dark:text-white'>
      <div className='flex items-center space-x-2'>
        <InputText
          className='w-32'
          name='min'
          value={values.min}
          onChange={changeInput}
          reset={() => handleReset('min')}
          placeholder='min'
          maxLength={11}
        />
        <InputText
          className='w-32'
          name='max'
          value={values.max}
          onChange={changeInput}
          reset={() => handleReset('max')}
          placeholder='max'
          maxLength={11}
        />
      </div>
      <button className='ml-auto block px-2 py-0.5' type='submit'>
        Apply
      </button>
    </form>
  );
};
