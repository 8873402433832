import React from 'react';
import { AuthTypes } from '../../../../shared/types';

const content = {
  [AuthTypes['sign-in']]: 'Sign in',
  [AuthTypes['sign-up']]: 'Sign up',
  [AuthTypes['reset-password']]: 'Reset password',
  [AuthTypes['forgot-password']]: 'Forgot password?',
};

const Title = ({ authType }: { authType: AuthTypes }) => {
  return <h1 className='block text-2xl font-bold text-gray-800 dark:text-white'>{content[authType]}</h1>;
};

export default Title;
