import 'whatwg-fetch';
import {COUNT_RECORDS_ON_PAGE, MEILISEARCH_API, MEILISEARCH_INDEX, MEILISEARCH_KEY} from '../utils/constants';

const getProjects = async (searchValue: string) => {
  return fetch(`${MEILISEARCH_API}/multi-search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${MEILISEARCH_KEY}`,
    },
    body: JSON.stringify({
      "queries":[
          {
            "indexUid": MEILISEARCH_INDEX,
            "q": searchValue,
            "filter": "ParentSlug = '0' AND REAL_HOMES_property_id IS EMPTY",
            "limit": COUNT_RECORDS_ON_PAGE,
            "offset": 0
          }
      ]
    })
  }).then(res => res.json())
      .then(res => res?.results[0]?.hits?.map(({ ID, Title, Slug }) => ({ ID, Title, Slug })));
};

export default getProjects;
