import request from '../utils/request';

interface Props {
  requestUrl: string;
  body: Record<string, string>;
}

interface IPromise {
  jwt: string;
  user: Record<string, string>;
}

export const authRequest = async ({ requestUrl, body }: Props): Promise<IPromise> => {
  return await request(requestUrl, { method: 'POST', body });
};
