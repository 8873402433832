import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import auth from '../utils/auth';
import { AuthTypes } from '../shared/types';

const PrivateRoute = () => {
  //return auth.getToken() ? <Outlet /> : <Navigate to={`/auth/${AuthTypes['sign-in']}`} />;
  return <Outlet />;
};

export default PrivateRoute;
