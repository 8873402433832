import { configureStore } from '@reduxjs/toolkit';
// import leadsReducer from './leads/leadsSlice';
import userReducer from './user/userSlice';
import notificationReducer from './notification/notificationSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
    // leads: leadsReducer,
  },
});
