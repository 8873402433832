import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import ArrowUp from '../../assets/icons/ArrowUp';

const SORT_STATE = {
  asc: 'asc',
  desc: 'desc',
  disabled: null,
};

const withSortFunction =
  (Component) =>
  ({ sortedField, children, className, ...props }) => {
    const [sort, setSort] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      if (searchParams.has(sortedField)) {
        setSort(searchParams.get(sortedField));
      }
    }, []);

    const handleClick = () => {
      switch (searchParams.get(sortedField)) {
        case SORT_STATE.desc:
          setSearchParams((current) => {
            current.delete(sortedField);
            return current;
          });
          setSort(SORT_STATE.disabled);
          break;
        case SORT_STATE.asc:
          setSearchParams((current) => {
            current.set(sortedField, SORT_STATE.desc);
            return current;
          });
          setSort(SORT_STATE.desc);
          break;
        default:
          setSearchParams((current) => {
            current.set(sortedField, SORT_STATE.asc);
            return current;
          });
          setSort(SORT_STATE.asc);
          break;
      }
    };

    return (
      <Component {...props} className={cn('cursor-pointer', className)} onClick={handleClick}>
        {children}{' '}
        <ArrowUp
          className={cn('w-4', sort === SORT_STATE.desc && 'rotate-180', sort === SORT_STATE.disabled && 'hidden')}
        />
      </Component>
    );
  };

export default withSortFunction;
