export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const COUNT_RECORDS_ON_PAGE = 10;
export const ERROR_MESSAGES = {
  minLength: (count) => `Minimal length must be ${count}!`,
  required: 'The field is required!',
  email: 'Entered value does not match email format',
  confirmRequired: 'Please confirm your password!',
  confirm: 'The two passwords that you entered do not match!',
};
export const AUTH_TOKEN = 'auth-token';
export const API = `${BACKEND_URL}/api`;
export const MEILISEARCH_API = process.env.REACT_APP_MEILISEARCH_PATH
export const MEILISEARCH_KEY = process.env.REACT_APP_MEILISEARCH_KEY
export const MEILISEARCH_INDEX = process.env.REACT_APP_MEILISEARCH_INDEX
export const EMPTY_SEARCH_RESULT = 'Try other project name';
export const INITIAL_SEARCH_RESULT = 'Enter project name';
export const FILTERS_PARAMS_NAMES = {
  size: {
    from: 'size_from',
    to: 'size_to',
  },
  price: {
    from: 'price_from',
    to: 'price_to',
  },
  ownership: 'foreign_quota',
  floor: {
    from: 'floor_from',
    to: 'floor_to',
  },
  project_name: 'project_name',
};
export const SORT_PARAMS_NAMES = {
  company: 'company_sort',
  view: 'view_sort',
  price: 'price_sort',
  project: 'project_sort',
  floor: 'floor_sort',
  size: 'size_sort',
};
