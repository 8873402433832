import React, { useCallback, useRef, useState } from 'react';

import Avatar from './Profile.components/Avatar/Avatar';
import Menu from './Profile.components/Menu/Menu';
import { useClickAway } from 'react-use';

const Profile = () => {
  const [active, setActive] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setActive(false);
  });

  const toggleMenu = useCallback(() => {
    setActive(!active);
  }, [active]);

  return (
    <div className='md:relative md:z-30 md:ml-4 flex-shrink-0' ref={ref}>
      <Avatar onClick={toggleMenu} />
      <Menu
        isActive={active}
        className='absolute -right-2 z-10 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5'
      />
    </div>
  );
};

export default Profile;
