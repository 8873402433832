import { AuthTypes } from '../../shared/types';
import { ERROR_MESSAGES } from '../../utils/constants';

export const views = {
  [AuthTypes['sign-in']]: [
    {
      title: 'Email address',
      label: 'email',
      type: 'email',
      options: {
        required: ERROR_MESSAGES.required,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: ERROR_MESSAGES.email,
        },
      },
    },
    {
      title: 'Password',
      label: 'password',
      type: 'password',
      options: {
        required: ERROR_MESSAGES.required,
        minLength: {
          value: 6,
          message: ERROR_MESSAGES.minLength(6),
        },
      },
      extraLink: true,
    },
    {
      label: 'rememberMe',
      type: 'checkbox',
      options: {},
    },
  ],

  [AuthTypes['sign-up']]: [
    {
      title: 'Email address',
      label: 'email',
      type: 'email',
      options: {
        required: ERROR_MESSAGES.required,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: ERROR_MESSAGES.email,
        },
      },
    },
    {
      title: 'Username',
      label: 'username',
      type: 'text',
      options: {
        required: ERROR_MESSAGES.required,
        minLength: {
          value: 4,
          message: ERROR_MESSAGES.minLength(4),
        },
      },
    },
    {
      title: 'Password',
      label: 'password',
      type: 'password',
      options: {
        required: ERROR_MESSAGES.required,
        minLength: {
          value: 6,
          message: ERROR_MESSAGES.minLength(6),
        },
      },
    },
    {
      title: 'Confirm Password',
      label: 'confirmPassword',
      type: 'password',
      options: {
        required: ERROR_MESSAGES.confirmRequired,
        minLength: {
          value: 6,
          message: ERROR_MESSAGES.minLength(6),
        },
      },
    },
    {
      label: 'terms',
      type: 'checkbox',
      options: {
        required: ERROR_MESSAGES.required,
      },
    },
  ],

  [AuthTypes['forgot-password']]: [
    {
      title: 'Email address',
      label: 'email',
      type: 'email',
      options: {
        required: ERROR_MESSAGES.required,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: ERROR_MESSAGES.email,
        },
      },
    },
  ],

  [AuthTypes['reset-password']]: [
    {
      title: 'Password',
      label: 'password',
      type: 'password',
      options: {
        required: ERROR_MESSAGES.required,
        minLength: {
          value: 6,
          message: ERROR_MESSAGES.minLength(6),
        },
      },
    },
    {
      title: 'Confirm Password',
      label: 'passwordConfirmation',
      type: 'password',
      options: {
        required: ERROR_MESSAGES.confirmRequired,
        minLength: {
          value: 6,
          message: ERROR_MESSAGES.minLength(6),
        },
      },
    },
  ],
};
