import React, { PropsWithChildren } from 'react';
import Spinner from '../Spinner/Spinner';

interface Props extends PropsWithChildren {
  isLoading?: boolean;
}

const AuthContainer = ({ isLoading, children }: Props) => {
  return (
    <div className='dark:bg-slate-900 bg-gray-100 flex h-full min-h-screen items-center overflow-hidden'>
      <div className='w-full max-w-md mx-auto p-6'>
        <div className='bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700 relative'>
          {isLoading && (
            <div className='absolute z-10 inset-0 bg-gray-800 bg-opacity-60 flex items-center justify-center'>
              <Spinner className='w-12 h-12' />
            </div>
          )}
          <div className='p-4 sm:p-7'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
