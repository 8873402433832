import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContainer from '../components/Auth/AuthContainer';
import Spinner from '../components/Spinner/Spinner';
import { API } from '../utils/constants';
import request from '../utils/request';
import { useQuery } from '@tanstack/react-query';

const confirmation_url = `${API}/auth/email-confirmation`;

const EmailConfirmation = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const url = new URL(confirmation_url);
  const confirmationCode = searchParams.get('confirmation');
  url.searchParams.set('confirmation', confirmationCode);
  const handleConfirm = async () => {
    return await request(url.toString());
  };

  const { status } = useQuery(['confirmation'], handleConfirm, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (status !== 'loading') {
      navigate('/', { replace: true });
    }
  }, [status, navigate]);

  return (
    <AuthContainer>
      <div className='flex flex-col items-center space-y-4'>
        <div className='text-white text-xl'>Wait for redirect after confirmation</div>
        <Spinner className='w-16 h-16' />
      </div>
    </AuthContainer>
  );
};

export default EmailConfirmation;
